import React from "react";
import background from "../assets/header.jpg";
import logo from "../assets/kbrial logo.png";
import tech from "../assets/tech.jpg";
import graphic from "../assets/graphic.jpg";
import back from "../assets/back.mp4";
import { Fade } from "react-awesome-reveal";

export default function Welcome() {
  return (
    <div>
      <div
        className="w-full h-screen bg-cover bg-center flex justify-center items-center"
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className="w-full h-full bg-black bg-opacity-50 text-white flex">
          <div className="w-full flex flex-col items-end justify-center">
            <div className="flex flex-col items-center w-1/2 mr-5">
              <Fade direction="up" duration={2000}>
                <img src={logo} alt="logo" className="w-96" />
                <div className="mt-10">
                  <h1 className="text-5xl font-semibold">
                    Descubre Soluciones Tecnológicas Innovadoras
                  </h1>
                  <p className="text-xl mt-5">
                    En Kabrial Studio, desarrollamos soluciones tecnológicas y
                    gráficas a la medida para impulsar tu negocio al siguiente
                    nivel.
                  </p>

                  <button className="bg-[#4849E8] px-12 py-3 rounded-lg mt-5 hover:bg-[#3c3cc1] transition-all duration-300">
                    Contactanos
                  </button>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>
      <div className="relative w-full overflow-hidden mt-5 mb-5">
        <video
          className="absolute top-0 left-0 w-full h-full object-cover"
          src={back}
          autoPlay
          loop
          muted
        />

        <div className="w-full h-96 bg-black bg-opacity-70 flex justify-center items-center">
          <Fade direction="up" duration={2000}>
            <div className="relative z-10 flex flex-col items-center justify-center h-full">
              <h2 className="text-white text-6xl ">Nuestros</h2>
              <h2 className="text-[#4849E8] text-6xl ">Servicios</h2>
              <p className="text-white text-xl mt-5 text-center">
                En Kabrial Studio, ofrecemos soluciones tecnológicas y gráficas
                a la medida para impulsar tu negocio al siguiente nivel.
              </p>
            </div>
          </Fade>
        </div>
      </div>

      <div className="flex">
        <div
          className="w-1/2 h-96  bg-cover bg-center"
          style={{ backgroundImage: `url(${graphic})` }}
        >
          <div className="w-full h-96 bg-black bg-opacity-50 hover:bg-opacity-80 hover:opacity-100 transition-all duration-300">
            <div>
              <h2 className="text-white text-6xl opacity-70">Soluciones</h2>
              <h2 className="text-[#FC00C2] text-6xl opacity-70 mt-7">
                Gráficas
              </h2>
            </div>
          </div>
        </div>
        <div
          className="w-1/2 h-96 bg-black bg-opacity-50 hover:bg-opacity-30 transition-all duration-300 bg-cover bg-center"
          style={{ backgroundImage: `url(${tech})` }}
        >
          <div className="w-full h-96 bg-black bg-opacity-50 hover:bg-opacity-80 hover:opacity-100 transition-all duration-300">
            <div>
              <h2 className="text-white text-6xl opacity-70">Soluciones</h2>
              <h2 className="text-[#D0F344] text-6xl opacity-70 mt-7">
                Tecnológicas
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div>asdad</div>
    </div>
  );
}
